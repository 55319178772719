import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

//Modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScopeTabComponent } from './components/scope/scope-tab/scope-tab.component';
import { FooterComponent } from './components/footer/footer.component';
import { ConfigService } from './services/config.service';

export function initConfig(configService: ConfigService) {
  return () => configService.loadConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    ScopeTabComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatIconModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    MatSidenavModule,
    RouterModule,
    MatToolbarModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatChipsModule,
    MatDividerModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatExpansionModule,
    MatTreeModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatListModule,
    NgChartsModule,
    MatTabsModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatStepperModule,
    DragDropModule,
    MatTooltipModule,
    MatBadgeModule,
    MatButtonToggleModule,
  ],
  exports: [
    //Modules
    MatOptionModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDialogModule,
    DragDropModule,
    MatSlideToggleModule,
    RouterModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatDividerModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSelectModule,
    MatRippleModule,
    MatListModule,
    MatTabsModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgChartsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatDatepickerModule,
    MatTreeModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatCardModule,
    MatBadgeModule,
    MatButtonToggleModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [ConfigService], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
