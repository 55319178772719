import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Hi-SimChart';

  actualSpeed: string = '0';
  desiredSpeed: string = '0';
  speedDiff: string = '0';

  constructor() {}

  updateActualSpeed(actualSpeed: string) {
    this.actualSpeed = actualSpeed;
  }
  updateSpeedDiff(speedDiff: string) {
    this.speedDiff = speedDiff;
  }
  updateDesiredSpeed(desiredSpeed: string) {
    this.desiredSpeed = desiredSpeed;
  }
}
