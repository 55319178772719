import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import HISIMChart from './chart/hisimChart';
import DataModel from 'src/app/models/data-model';
import { HttpClient } from '@angular/common/http';

export interface ChartMeta {
  labelYLeft: string;
  leftUnit: string;
  labelXBottom: string;
  bottomUnit: string;
  thickness: number;
  dash: number[][];
  scope: DataModel;
}

@Component({
  selector: 'app-scope-tab',
  templateUrl: './scope-tab.component.html',
  styleUrls: ['./scope-tab.component.css'],
})
export class ScopeTabComponent implements OnInit, AfterViewChecked {
  chart!: HISIMChart;

  context!: CanvasRenderingContext2D;
  config!: ChartConfiguration;

  @ViewChild('canvas')
  canvas!: ElementRef<HTMLCanvasElement>;

  chartOptions: any = {
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        titleFont: {
          size: 20,
        },
        bodyFont: {
          size: 20,
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
        callbacks: {
          title: (tooltipItems: any) => {
            return tooltipItems[0].dataset.label;
          },
          label: (tooltipItem: any) => {
            const { x, y } = tooltipItem.parsed;
            const { xAxisID, yAxisID } = tooltipItem.dataset;
            return `${xAxisID}: ${x.toFixed()}m, ${yAxisID}: ${y.toFixed()}km/h`;
          },
        },
      },
      deferred: {
        xOffset: 150,
        yOffset: '50%',
        delay: 500,
      },
    },
  };

  chartMeta!: ChartMeta;

  @Output() updateDesiredSpeed = new EventEmitter<string>();
  @Output() updateSpeedDiff = new EventEmitter<string>();
  @Output() updateActualSpeed = new EventEmitter<string>();

  currentDataIndex = 0;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _httpClient: HttpClient,
  ) {}

  ngOnInit() {
    this.loadJsonData();
  }

  ngAfterViewInit() {
    this.context = this.canvas.nativeElement.getContext('2d')!;
    this.config = {
      type: 'line',
      data: {
        datasets: [],
      },
      options: this.chartOptions,
    };
    this.chart = new HISIMChart(this.context, this.config);
  }

  ngAfterViewChecked() {
    this._changeDetectorRef.detectChanges();
  }

  draw(chartMeta: ChartMeta) {
    this.chart.addData(chartMeta);
  }

  loadJsonData() {
    this._httpClient
      .get(`assets/datas/big-data-4.json`)
      .subscribe((data: any) => {
        this.chartMeta = {
          labelYLeft: 'Speed',
          leftUnit: 'km/h',
          labelXBottom: 'Position',
          bottomUnit: 'm',
          thickness: 2,
          dash: [[5, 5]],
          scope: {
            id: data.data.id,
            samplingTime: data.data.samplingTime,
            x: data.data.x,
            y: data.data.y,
            name: 'Train Speed / Position',
          },
        };
        this.draw(this.chartMeta);
        this.start();
      });
  }

  start() {
    setInterval(() => {
      if (this.chart.data?.datasets?.length === 0) return;

      if (this.chart.data?.datasets?.length === 1) {
        let chartMeta = {
          ...this.chartMeta,
          scope: {
            id: 1,
            samplingTime: 0.1,
            x: this.chartMeta.scope.x,
            y: [],
            name: "Pilot's Speed / Position",
          },
        };
        this.draw(chartMeta);
      }

      this.chart.data?.datasets[1].data?.push(
        (this.chart.data.datasets[0].data[this.currentDataIndex] as number) +
          10,
      );

      this.updateDesiredSpeed.emit(
        (
          this.chart.data.datasets[0].data[this.currentDataIndex] as number
        ).toFixed() + ' km/h',
      );

      this.updateActualSpeed.emit(
        (
          (this.chart.data.datasets[0].data[this.currentDataIndex] as number) +
          10
        ).toFixed() + ' km/h',
      );

      this.updateSpeedDiff.emit(
        (this.chart.data.datasets[0].data[this.currentDataIndex] as number) -
          (this.chart.data.datasets[0].data[this.currentDataIndex] as number) +
          10 +
          ' km/h',
      );

      this.chart.update();

      const chartArea = this.chart.chartArea!;
      this.chart.tooltip!.setActiveElements(
        [
          {
            datasetIndex: 0,
            index: this.currentDataIndex,
          },
          {
            datasetIndex: 1,
            index: this.currentDataIndex,
          },
        ],
        {
          x: (chartArea.left + chartArea.right) / 2,
          y: (chartArea.top + chartArea.bottom) / 2,
        },
      );
      
      this.currentDataIndex += 1;
      this.chart.update();
    }, 100);
  }
}
