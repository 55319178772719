import { Chart, ChartConfiguration } from 'chart.js';
import { ChartMeta } from '../scope-tab.component';

export default class HISIMChart extends Chart {
  yAxisSet!: Set<string>;
  xAxisSet!: Set<string>;
  constructor(context: CanvasRenderingContext2D, config: ChartConfiguration) {
    super(context, config);
    this.yAxisSet = new Set();
    this.xAxisSet = new Set();
  }

  addData(chartMeta: ChartMeta) {
    const index = this.data.datasets.findIndex(
      (dataset) => dataset.label === chartMeta.scope.name,
    );
    if (index !== -1) {
      this.data.datasets[index].data = chartMeta.scope.x;
      this.update();
    }

    this.data.labels = chartMeta.scope.x;

    this.data.datasets.push({
      label: `${chartMeta.scope.name}`,
      fill: false,
      data: chartMeta.scope.y,
      borderWidth: chartMeta.thickness,
      borderDash: chartMeta.dash,
      yAxisID: chartMeta.labelYLeft,
      xAxisID: chartMeta.labelXBottom,
    });

    if (!this.yAxisSet.has(chartMeta.labelYLeft)) {
      this.options.scales![chartMeta.labelYLeft] = {
        // id: data.name,
        type: 'linear',
        position: 'left',
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: `${chartMeta.labelYLeft} (${chartMeta.leftUnit})`,
        },
        ticks: {
          callback: (val, index, ticks) =>
            index === 0 || index === ticks.length - 1 ? null : val,
        },
      };
      this.yAxisSet.add(chartMeta.labelYLeft);
    }

    if (!this.xAxisSet.has(chartMeta.labelXBottom)) {
      this.options.scales![chartMeta.labelXBottom] = {
        // id: data.name,
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: `${chartMeta.labelXBottom} (${chartMeta.bottomUnit})`,
        },
        ticks: {
          callback: (val, index, ticks) =>
            index === 0 || index === ticks.length - 1 ? null : val,
        },
      };
      this.xAxisSet.add(chartMeta.labelXBottom);
    }
    this.update();
  }
}
