<div class="d-flex row">
    <div class="col-12 col-md-10">
        <app-scope-tab (updateDesiredSpeed)="updateDesiredSpeed($event)" (updateSpeedDiff)="updateSpeedDiff($event)"
            (updateActualSpeed)="updateActualSpeed($event)"></app-scope-tab>
    </div>
    <div class="col-12 col-md-2 d-flex flex-column p-2 border rounded">
        <p>
            <strong>Actual Speed:</strong> {{actualSpeed}}
        </p>
        <p>
            <strong>Desired Speed:</strong> {{desiredSpeed}}
        </p>
        <p>
            <strong>Speed Diff:</strong> {{speedDiff}}
        </p>
    </div>
    <app-footer></app-footer>
</div>